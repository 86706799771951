import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import '../static/css/pages.css';
import CartContext from '../context/CartContext';



function HomePage() {

  // // Getting our initial customer value
  let {user} = useContext(AuthContext);
  let {customer} = useContext(CartContext);
  console.log(customer)
  
  return (
    <>
      <div className='main'>
        <div className='landing'>
          <header className='landing-header'>
          {user ? (
                <div> 
                  <h1>Welcome Home {user.username}</h1>
                  <p>Discover a world of knowledge with our vast collection of books.</p>
                  <a href='/store' className='cta-button'>Shop Now</a>
                </div>
              ) : (
                <div>
                  <h1>Welcome to Your Bookstore</h1>
                  <p>Discover a world of knowledge with our vast collection of books.</p>
                  <a href='/store' className='cta-button'>Shop Now</a>
                </div>
              )
          }
          </header>
        </div>
      </div>
    </>
  )
}

export default HomePage;
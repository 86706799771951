import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import { StoreProvider } from './context/StoreContext';
import RegisterPage from './pages/RegisterPage';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import CatPage from './pages/CatPage';
import ProductPage from './pages/ProductPage';
import OrderPage from './pages/OrderPage';
import CheckoutPage from './pages/CheckoutPage';
import SuccesPage from './pages/SuccesPage';
import PrivateRoute from './utils/PrivateRoute';
import ScrollToTopButton from './utils/ScrollToTopButton';







function App() {


  return (
    <>
      <Router>
        <AuthProvider>
          <CartProvider>
            <StoreProvider>
              <Header/>
              <Routes> 
                <Route path='/' Component={HomePage}/>
                <Route path='/login' Component={LoginPage}/>
                <Route path='/register' Component={RegisterPage}/>
                <Route element={<PrivateRoute/>}>
                  <Route path='/store' Component={CatPage}/>
                  <Route path='/store/:id' Component={ProductPage}/>
                  <Route path='/my-order' Component={OrderPage}/>
                  <Route path='/my-order/checkout' Component={CheckoutPage}/>
                  <Route path='/my-order/success' Component={SuccesPage}/>
                </Route>
              </Routes>
	      <ScrollToTopButton/>
              <Footer/>
            </StoreProvider> 
          </CartProvider>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App;

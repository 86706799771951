
// utils/PrivateRoute.js
import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';



function IsAuthenticated() {
    const { user } = useContext(AuthContext);
    return !!user
};

const PrivateRoute = () => {
  return (
    IsAuthenticated() ? <Outlet /> : <Navigate to="/login" replace />
  );
};

export default PrivateRoute;


import { React, useContext }  from 'react';
import CartContext from '../context/CartContext';
import '../static/css/pages.css';
import { Link } from 'react-router-dom';
import StoreContext from '../context/StoreContext';
import ArrowButton from '../components/product/buttons/ArrowButton';
import { API_URL } from '../config';



function OrderPage() {


    const {
        items, 
        totalQuantity, 
        priceQuantity
        } = useContext(CartContext);
  
    const { order } = useContext(StoreContext);

    const order_id = order?.id;

 
    return (
      <>

        <div className='main'>
            <div className="box-element">
                <div className="cart-row">
                    <Link className="btn btn-outline-dark"
                        style={{maarginTop:'13px'}}
                        to='/store'>
                            &#x2190;
                            Continue Shopping
                    </Link>
                </div>
            
                <div className="cart-row">
                    <table className="table">
                        <tr>
                            <th className='th'><h5>Items:
                            <strong> {totalQuantity}</strong></h5></th>

                            <th className='th'><h5>Total:
                            <strong> ${priceQuantity}</strong></h5></th>

                            <th>

                                <form action={`${API_URL}create-checkout-session/${order_id}/`} method="POST">
                                    <button type="submit" style={{float:'center'}}
                                    class="btn btn-success hover-light">
                                        Checkout
                                    </button>
                                </form>


                            </th>

                        </tr>
                    </table>
                </div>    
            </div>
            <div className="box-element">
                <div className="cart-row">
                    <div className="col-lg-3" style={{ paddingLeft: '1.5rem' }}><strong>Item</strong></div>
                    <div className="col-lg-3"  style={{ paddingLeft: '1rem' }} ><strong >Price</strong></div>
                    <div className="col-lg-3" style={{ justifyContent: 'center' }}><strong >Quantity</strong></div>
                    <div className="col-lg-3"><strong >Total</strong></div>
                </div>
                
                {items.map((item, id) => (
                <div className="cart-row">
                    <div className="col-lg-3" style={{ paddingLeft: '1.5rem' }} key={id}>
                        <Link key={item.id} to={`/store/${item.id}`} style={{ textDecoration: 'none', color: 'maroon'}}>
                            {item.title}
                        </Link>
                        
                    </div>
                    <div className="col-lg-3"  style={{ paddingLeft: '1rem' }} key={id}>${item.price}</div>
                    <ArrowButton key={id} product={item} />
                    <div className="col-lg-3" key={id}>${(item.price*item.qty).toFixed(2)}</div>
                </div>
                ))}
  
          </div>
        </div>
          
    </>
  )
}

export default OrderPage;

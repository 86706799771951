import React, { useState, createContext, useContext } from 'react' ;
import CartContext from './CartContext';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { detailOrder, deleteOrder } from '../api/orderApi';
import { listOrderItem } from '../api/orderItemApi';
import { useNavigate } from 'react-router-dom';








const StoreContext = createContext();

export default StoreContext;

export const StoreProvider = ({children}) => {


  let {customer} = useContext(CartContext);
  const customer_id = String(customer?.id);

  // State for maintaining orderItem
  let [ orderItem, setOrderItem ] = useState([]);
  let [ order, setOrder ] = useState([]);


  // Getting or creating user order
  const { data: existingOrder } = useQuery(
    ['existingOrder', customer_id],
    () => detailOrder({ customer_id: customer_id }),

    {
        enabled: !!customer,

        staleTime: 1000 * 13, // 13 seconds

        onSuccess: (existingOrder) => {
            setOrder(existingOrder)
        },

        onError: (error) => {
            console.error('error creating order:', error);
        }
    }
  );


  const order_id = order?.id;

  // Getting a list of all our Order Items
  const { data: orderItemList } = useQuery(
    ['orderItemList', order_id],
    () => listOrderItem({ order_id: order_id }),

    {
        enabled: !!order,

        staleTime: 1000 * 10, // 10 seconds

        //
        onSuccess: (orderItemList) => {
         
            setOrderItem(orderItemList) 
            console.log('Order Item ListQ:', orderItemList);
        }, 
       
        onError: (error) => {
            console.error('error creating order:', error);
        }
    }
  );


  const queryClient = useQueryClient();

  const history = useNavigate()

  let {clearCart} = useContext(CartContext);


  const { mutate: clearCartMutation } = useMutation(
      deleteOrder,
      {
          onSuccess: () => {
              queryClient.invalidateQueries('orderItemList');
              console.log('Order deleted successfully');
          },
          onError: () => {
              console.log('Order failed to delete');
          }
      }
  );

  const handleClearCart = async (e) => {

      e.preventDefault();
      console.log('Clear Cart button clicked');

      // Check if the product already exists in the cart
      const order_id = order?.id;

      try {
          await clearCartMutation({id: order_id});
          clearCart();
	  queryClient.invalidateQueries('');
          console.log('Order deleted successfully');
          history('/');
      } catch (error) {
          console.error('Failed to delete Order', error);
      }
  };






  let contextData = {
      order: order,
      orderItem: orderItem,
      handleClearCart: handleClearCart

  }


  return (
      
      <StoreContext.Provider value={contextData}>
          {children}
      </StoreContext.Provider>

  )  
}



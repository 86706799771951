import { React } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { detailProduct } from '../api/productApi';
import { Card, Container, Row, Col } from 'react-bootstrap';
import '../static/css/pages.css';
import AddtcButton from '../components/product/buttons/AddtcButton';
import RemovetcButton from '../components/product/buttons/RemovetcButton';
import { API_URL } from '../config';


function ProductPage() {
  
  const {id} = useParams();

  // GATHERING PRODUCT DETAILS FROM API;
  const {data: product, isLoading, isError} = useQuery(
    ['product', id],
    () => detailProduct({id}), 
    {

      enabled: !!id, // Only fetch data when id is available

      staleTime: 1000 * 60 * 30, // 30 minutes

    }
  );

  
  const modifiedApiUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;

  console.log('Modified API URL:', modifiedApiUrl);

  const product_image = modifiedApiUrl + product?.imageUrl
  

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return (
      <p>
        Error fetching product details. {console.error(isError)}; {/* Display the error message */}
      </p>
    );
  }

  return (
    <>
      <div className='main'>
        <Container flex className='container' style={{ alignItems: 'center' }}>
          <Row>
            <Col md={6}>
              <Card key={id} className='box-element' style={{color: '#000'}}>
                <div className='product-img'>
                  <Card.Img className='mb-3' variant="top" 
                  src={product_image}
                  alt={product.title} style={{
                  marginLeft: '6%', alignItems: 'center', width: '88%', height: '412px', objectFit: 'fill' }}/>
                </div>
                <Card.Body>
                  <Card.Title>{product.title}</Card.Title>
                  <Card.Text>{product.description}</Card.Text>
                  <Card.Text>Price: ${product.price}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className='box-element' style={{color: '#000'}}>
                <Card.Body>
                  <Card.Title>Product Details</Card.Title>
                  <Card.Text>{product.summary}</Card.Text>
                  <AddtcButton product={product} />
                  <RemovetcButton product={product} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      
    </>
  )
}

export default ProductPage;


import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListProduct from '../components/product/ListProduct';
import '../static/css/pages.css';
import { listProduct } from '../api/productApi';
import Spinner from 'react-bootstrap/Spinner';
import { useQuery } from 'react-query';




const CatPage = () => {


    // Getting a list of all our Products
    let [productCat, setProductCat] = useState([]);

    const {data: list, isError, isLoading} = useQuery(['list'], listProduct, {
      onSuccess: (list) => {
        setProductCat(list)
      }
    });


    if (isLoading) {
      return <Spinner animation="border" />;
    }


    return (
        <>
            <div className='main'>
                <Container>
                    <Row>
                    {productCat.map((product) => (
                        <Col lg={1} className='col-lg-4'>
                            <ListProduct key={product.id} product={product} />
                        </Col>
                    ))}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CatPage; 



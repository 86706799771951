import './index.css';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddtcButton from './buttons/AddtcButton';
import { API_URL } from '../../config';



const ListProduct = ({ product }) => {

  const modifiedApiUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;

  console.log('Modified API URL:', modifiedApiUrl);

  const product_image = modifiedApiUrl + product.imageUrl
  
  return (
    <>
      <Card key={product.id} className='box-element' style={{color: '#000'}}>
        <div className='product-img'>
          <Card.Img className='mb-3' variant='top' src={product_image} alt={ product.title } style={{width: '100%', height: '396px'}}/>
          <Card.Body>
            <Card.Title>{product.title}, by {product.author}</Card.Title>
            <Card.Text>
              ${product.price}
            </Card.Text>
            <Button variant='secondary' style={{ marginRight: '10px', borderRadius: '7px' }}>
              <Link to={`/store/${product.id}`} style={{margin: 15, textDecoration: 'none', color:'inherit'}}>
                view
              </Link>
            </Button>
            <AddtcButton product={product} />
          </Card.Body>
        </div>
      </Card>
    </>
  )
}


export default ListProduct;

